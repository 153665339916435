import React from "react"

import Button from "../../shared/Button/Button"
import { getItem } from "../../util/functions"
import {
  Container,
  Text,
  Heading,
  Light,
  ConfirmationText,
} from "./ConfirmationComponent"

const PatientFormConfirmation = () => {
  const list = getItem("treatment")
  const getTreatments = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const list = getItem("treatment") ? getItem("treatment").split(", ") : ""
      let firstList
      if (list) {
        firstList = list.slice(0, list.length - 1).join(", ")
      }

      return (
        <>
          <span>{firstList || list[0]}</span>
          {list.length > 1 && (
            <>
              {" "}
              and <span>{list[list.length - 1]}</span>
            </>
          )}
        </>
      )
    }
  }
  return (
    <Container>
      <Text>
        <Heading>
          Hi {getItem("firstName")} {getItem("lastName")},
        </Heading>
        <Light>Thank you for getting in touch</Light>
        <ConfirmationText>
          {!getItem("treatment") ? (
            <>
              A member of our <span>White and Co {getItem("practice")}</span>{" "}
              team will be in contact soon to discuss your enquiry.
            </>
          ) : (
            <>
              {" "}
              A member of our{" "}
              <span>
                White and Co {getItem("patient-referral-form-dropdown1")}
              </span>{" "}
              team will be in contact soon to discuss your enquiry on{" "}
              {getTreatments()}.
            </>
          )}
        </ConfirmationText>
      </Text>
      <Button text="Continue" href={process.env.BASE} formConfirmation />
    </Container>
  )
}

export default PatientFormConfirmation
